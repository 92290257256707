import React, { useEffect, useState } from 'react'
import CustomizedDataGrid from '../../../dashboard/components/CustomizedDataGrid'
import FormDialog from '../../../components/FormDialog'
import { districtFormFields } from '../../../utils/form-fields'
import { districtsTableColumns } from '../../../utils/table-columns'
import { authPostRequest } from '../../../services/api-service'
import { createDistrictUrl, deleteDistrictUrl, getAllDistrictsByPaginationUrl, updateDistrictUrl } from '../../../services/urls'
import { useCallback } from 'react'
import { useAuth } from '../../../hooks/use-auth'
import { Box, Button } from '@mui/material'
import { rowDirection } from '../../../utils/styles'
import View from '../components/View'
import { DeleteRounded, EditRounded, RemoveRedEyeRounded } from '@mui/icons-material'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import { toast } from 'react-toastify'

const CREATE_TITLE = "Create District";
const UPDATE_TITLE = "Update District";
function Districts({
    openCreateDialog,
    setOpenCreateDialog
}) {
    const auth = useAuth()
    const [parcelDetails, setParcelDetails] = useState({})
    const [loading, setLoading] = useState(true)
    const [deleting, setDeleting] = useState(false)
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [viewDistrict, setViewDistrict] = React.useState(false);
    const [deleteDistrict, setDeleteDistrict] = React.useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [dialogTitle, setDialogTitle] = React.useState(CREATE_TITLE);

    const getDistricts = useCallback(
        () => {
            setLoading(true)
            authPostRequest(
                getAllDistrictsByPaginationUrl,
                {
                    query: auth.query,
                    sort: "id desc",
                    page: paginationModel.page + 1,
                    limit: paginationModel.pageSize
                },
                (data) => {
                    setParcelDetails(data)
                    setLoading(false)
                },
                (error) => {
                    setLoading(false)
                }
            )
        }, [auth.query, paginationModel]
    )

    const handleDeletePackage = () => {
        for (let index = 0; index < rowSelectionModel.length; index++) {
            authPostRequest(
                deleteDistrictUrl,
                { id: rowSelectionModel[index].id },
                (data) => {
                    if (index === rowSelectionModel.length - 1) {
                        toast.success(data.message);
                        getDistricts()
                        setRowSelectionModel([]);
                        setDeleting(false)
                        setDeleteDistrict(false)
                    }
                },
                (error) => {
                    if (index === rowSelectionModel.length - 1) {
                        setDeleting(false)
                    }
                    toast.error(error?.response?.data?.message);
                }
            )
        }
    }

    useEffect(() => {
        getDistricts()
    }, [getDistricts])

    return (
        <>
            {openCreateDialog && (
                <FormDialog
                    open={openCreateDialog}
                    handleClose={() => {
                        if (
                            dialogTitle === CREATE_TITLE ||
                            (dialogTitle === UPDATE_TITLE && rowSelectionModel.length - 1 === currentIndex)
                        ) {
                            setCurrentIndex(0);
                            getDistricts();
                            setRowSelectionModel([]);
                            setDialogTitle(CREATE_TITLE)
                            setOpenCreateDialog(false);
                        } else {
                            setCurrentIndex(currentIndex + 1);
                            setOpenCreateDialog(true);
                        }
                    }}
                    dialogTitle={dialogTitle}
                    fields={districtFormFields}
                    values={[
                        {
                            id: rowSelectionModel?.[currentIndex]?.id || 0,
                            region_id: 0,
                            district_name: rowSelectionModel?.[currentIndex]?.district_name || "",
                        },
                    ]}
                    url={dialogTitle === CREATE_TITLE ? createDistrictUrl : updateDistrictUrl}
                />
            )}
            {viewDistrict &&
                <View
                    open={viewDistrict}
                    handleClose={() => setViewDistrict(false)}
                />
            }
            {deleteDistrict &&
                <ConfirmationDialog
                    open={deleteDistrict}
                    handleClose={() => setDeleteDistrict(false)}
                    handleSubmit={handleDeletePackage}
                    isSubmitting={deleting}
                    title={"Delete District"}
                    content={"Are you sure you want to delete the selected district?"}
                />
            }
            {rowSelectionModel.length > 0 &&
                <Box
                    sx={rowDirection}
                >
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<RemoveRedEyeRounded />}
                        onClick={() => {
                            setViewDistrict(true)
                        }}
                    >
                        View
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<EditRounded />}
                        onClick={() => {
                            setDialogTitle(UPDATE_TITLE)
                            setOpenCreateDialog(true)
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<DeleteRounded />}
                        onClick={() => {
                            setDeleteDistrict(true)
                        }}
                    >
                        Delete
                    </Button>
                </Box>
            }
            <CustomizedDataGrid
                loading={loading}
                columns={districtsTableColumns}
                data={parcelDetails}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
            />
        </>
    )
}

export default Districts
export const baseUrl = "https://www.service.smai.co.tz/api/v1/"

//USER MANAGEMENT ROUTES
export const registerUserUrl = `${baseUrl}register/user`
export const getUserUrl = `${baseUrl}get/user`
export const getUsersUrl = `${baseUrl}get/users`
export const loginByEmailPasswordUrl = `${baseUrl}login/user`

//PARCEL TYPES MANAGEMENT ROUTES
export const createParcelTypeUrl = `${baseUrl}create/parcel/type`
export const getAllParcelTypesByPaginationUrl = `${baseUrl}get/all/parcel/types/by/pagination`
export const updateParcelTypeUrl = `${baseUrl}update/parcel/type`
export const deleteParcelTypeUrl = `${baseUrl}delete/parcel/type`

//REGIONS MANAGEMENT ROUTES
export const createRegionUrl = `${baseUrl}add/region`
export const getAllRegionsByPaginationUrl = `${baseUrl}get/all/regions/by/pagination`
export const updateRegionUrl = `${baseUrl}update/region`
export const deleteRegionUrl = `${baseUrl}delete/region`

//DISTRICT MANAGEMENT ROUTES
export const createDistrictUrl = `${baseUrl}add/district`
export const getAllDistrictsByPaginationUrl = `${baseUrl}get/all/districts/by/pagination`
export const updateDistrictUrl = `${baseUrl}update/district`
export const deleteDistrictUrl = `${baseUrl}delete/district`

//WARD MANAGEMENT ROUTES
export const createWardUrl = `${baseUrl}add/ward`
export const getAllWardsByPaginationUrl = `${baseUrl}get/all/wards/by/pagination`
export const updateWardUrl = `${baseUrl}update/ward`
export const deleteWardUrl = `${baseUrl}delete/ward`

//ORDERS MANAGEMENT ROUTES
export const getOverviewStatistics = `${baseUrl}get/overview/statistics`
export const getAllOrdersByPaginationUrl = `${baseUrl}get/all/orders/by/pagination`
export const getProductsOrdersTopRegionsUrl = `${baseUrl}get/products/orders/top/regions`
import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import ChartUserByCountry from './ChartUserByCountry';
import TopRegionsOrdersBarChart from './TopRegionsOrdersBarChart';
// import SessionsChart from './SessionsChart';
import StatCard from './StatCard';
import { useNavigate } from 'react-router-dom';
import { authGetRequest } from '../../services/api-service';
import { getOverviewStatistics } from '../../services/urls';
import { Skeleton } from '@mui/material';

export default function MainGrid() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  const fetchData = () => {
    authGetRequest(
      getOverviewStatistics,
      (response) => {
        setData([
          {
            title: 'Total Users',
            value: response.total_users,
            interval: 'All time',
            trend: 'up',
            route: '/users',
          },
          {
            title: 'Total Agents',
            value: response.total_agents,
            interval: 'All time',
            trend: 'up',
            route: '/users',
          },
          {
            title: 'Total Drivers',
            value: response.total_drivers,
            interval: 'All time',
            trend: 'up',
            route: '/users',
          },
          {
            title: 'Total Admins',
            value: response.total_admins,
            interval: 'All time',
            trend: 'up',
            route: '/users',
          },
          {
            title: 'Total Orders',
            value: response.total_orders,
            interval: 'All time',
            trend: 'up',
            route: '/orders',
          },
          {
            title: 'Total Pending Orders',
            value: response.total_pending_orders,
            interval: 'All time',
            trend: 'up',
            route: '/orders',
          },
          {
            title: 'Total Processing Orders',
            value: response.total_processing_orders,
            interval: 'All time',
            trend: 'up',
            route: '/orders',
          },
          {
            title: 'Total Taken Orders',
            value: response.total_taken_orders,
            interval: 'All time',
            trend: 'up',
            route: '/orders',
          },
        ])
        setLoading(false)
      },
      () => {
        setLoading(false)
      }
    )
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Overview
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {loading && Array.from({ length: 8 }, (_, i) => i + 1).map((_, index) => {
          return (
            <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
              <Skeleton variant='rectangular' height={130} />
            </Grid>
          );
        })}
        {!loading && data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }} onClick={() => navigate(card.route)}>
            <StatCard {...card} />
          </Grid>
        ))}
        {/* <Grid size={{ xs: 12, md: 6 }}>
          <SessionsChart />
        </Grid> */}
        <Grid size={{ xs: 12, md: 12 }}>
          <TopRegionsOrdersBarChart />
        </Grid>
      </Grid>
      {/* <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Details
      </Typography>
      <Grid container spacing={2} columns={12}>
        <Grid size={{ xs: 12, lg: 3 }}>
          <ChartUserByCountry />
        </Grid>
      </Grid> */}
    </Box>
  );
}

import { Avatar, Box, Chip, Typography } from "@mui/material";

function renderStatus(status) {
    const colors = {
        Online: 'success',
        Offline: 'default',
        Active: 'success',
        Disabled: 'error',
        PENDING: 'error',
        YES: 'success',
        NO: 'default',
        RECEIVED: 'error',
        NEEDTOCALLAGAIN: 'warning',
        CONFIRMED: 'default',
        PROCESSING: 'secondary',
        INTRANSIT: 'info',
        DELIVERED: 'success',
        CANCELLED: 'error',
        RETURNED: 'error',
        REFUNDED: 'error',
        DUPLICATED: 'error',
        OUTOFSTOCK: 'error'
    };

    return <Chip label={status} color={colors[status]} size="small" />;
}

export function renderAvatar(params) {
    if (params.value == null) {
        return '';
    }

    return (
        <Avatar
            sx={{
                backgroundColor: params.value.color,
                width: '24px',
                height: '24px',
                fontSize: '0.85rem',
            }}
        >
            {params.value.name.toUpperCase().substring(0, 1)}
        </Avatar>
    );
}

export function renderSenderDetails(params) {
    return (
        <Box>
            <Typography><b>Name:</b> {params.row.sender_name}</Typography>
            <Typography><b>Phone:</b> {params.row.sender_phone_no}</Typography>
            <Typography>
                <b>Location:</b> {`${params.row.sender_region}, ${params.row.sender_district}, ${params.row.sender_street}`}
            </Typography>
        </Box>
    );
}

export function renderReceiverDetails(params) {
    return (
        <Box>
            <Typography><b>Name:</b> {params.row.receiver_name}</Typography>
            <Typography><b>Phone:</b> {params.row.receiver_phone_no}</Typography>
            <Typography>
                <b>Location:</b> {`${params.row.receiver_region}, ${params.row.receiver_district}`}
            </Typography>
        </Box>
    );
}

export function renderDistricts(params) {
    return (
        <Box>
            <Typography>
                {params.row.districts.map((district) => district.district_name).join(', ')}
            </Typography>
        </Box>
    );
}

export const ordersTableColumns = [
    {
        field: 'order_no',
        headerName: 'Order No',
        flex: 1,
    },
    {
        field: 'chinese_express_no',
        headerName: 'Chinese Express No',
        flex: 1,
    },
    {
        field: 'transaction_id',
        headerName: 'Transaction Ref',
        flex: 1,
    },
    {
        field: 'created_at',
        headerName: 'Date',
        flex: 1,
    },
    {
        field: 'sender_details',
        headerName: 'Sender Details',
        flex: 1,
        renderCell: (params) => renderSenderDetails(params),
    },
    {
        field: 'receiver_details',
        headerName: 'Receiver Details',
        flex: 1,
        renderCell: (params) => renderReceiverDetails(params),
    },
    {
        field: 'cash_on_delivery',
        headerName: 'COD',
        flex: 1,
        renderCell: (params) => renderStatus(params.value),
    },
    {
        field: 'order_status',
        headerName: 'Order Status',
        flex: 1,
        renderCell: (params) => renderStatus(params.value),
    },
    {
        field: 'payment_status',
        headerName: 'Payment Status',
        flex: 1,
        renderCell: (params) => renderStatus(params.value),
    },
];

export const usersTableColumns = [
    {
        field: 'id',
        headerName: 'ID',
    },
    {
        field: 'first_name',
        headerName: 'First Name',
        flex: 1,
        minWidth: 200
    },
    {
        field: 'last_name',
        headerName: 'Last Name',
        flex: 1,
        minWidth: 200
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'phone_no',
        headerName: 'Phone Number',
        flex: 1,
        minWidth: 120,
    },
    {
        field: 'gender',
        headerName: 'Gender',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'created_at',
        headerName: 'Date',
        flex: 1,
        minWidth: 100,
    },
];

export const parcelTypesTableColumns = [
    {
        field: 'id',
        headerName: 'ID',
    },
    {
        field: 'parcel_type',
        headerName: 'Parcel Type',
        flex: 1,
        minWidth: 200
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 80,
        renderCell: (params) => renderStatus(params.value ? 'Active' : 'Disabled'),
    },
    {
        field: 'created_at',
        headerName: 'Date',
        flex: 1,
        minWidth: 80,
    },
];

export const regionsTableColumns = [
    {
        field: 'region_name',
        headerName: 'Region',
        flex: 1.5,
        minWidth: 200
    },
    {
        field: 'districts',
        headerName: 'Districts',
        flex: 1,
        renderCell: (params) => renderDistricts(params),
    },
    {
        field: 'created_at',
        headerName: 'Date',
        headerAlign: 'right',
        align: 'right',
        flex: 1,
        minWidth: 80,
    },
];

export const districtsTableColumns = [
    {
        field: 'district_name',
        headerName: 'District',
        flex: 1.5,
        minWidth: 200
    },
    {
        field: 'region_name',
        headerName: 'Region',
        flex: 1.5,
        minWidth: 200
    },
    {
        field: 'created_at',
        headerName: 'Date',
        headerAlign: 'right',
        align: 'right',
        flex: 1,
        minWidth: 80,
    },
];

export const wardsTableColumns = [
    {
        field: 'ward',
        headerName: 'Ward',
        flex: 1.5,
        minWidth: 200
    },
    {
        field: 'created_at',
        headerName: 'Date',
        headerAlign: 'right',
        align: 'right',
        flex: 1,
        minWidth: 80,
    },
];
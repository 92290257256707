import React from 'react'
import { CalendarMonthOutlined, Circle, Close, LocalPhoneOutlined, LocalShippingOutlined, LocationOnOutlined, MoneyOutlined, PersonOutline } from '@mui/icons-material';
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid2, IconButton, List, ListItem, ListItemAvatar, ListItemText, Slide, Typography } from '@mui/material'
import { formatDate, formatMoney } from '../../../utils/helpers';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ViewOrder({ open, handleClose, rowSelectionModel, currentIndex, setCurrentIndex }) {
    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    }

    const handleNext = () => {
        if (rowSelectionModel.length - 1 > currentIndex) {
            setCurrentIndex(currentIndex + 1);
        }
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="view-order-dialog"
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogActions>
                <DialogTitle variant='h4' sx={{ mr: "auto" }}>
                    Information for Order No. {rowSelectionModel?.[currentIndex]?.order_no}
                </DialogTitle>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <Close />
                </IconButton>
            </DialogActions>
            <DialogContent>
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Typography variant='h5'>Sender Information</Typography>
                        <Box sx={{ display: "flex", mb: 1 }}>
                            <PersonOutline />
                            <Typography variant="body1" sx={{ ml: 2 }}>
                                <b>Full Name:</b> {rowSelectionModel?.[currentIndex]?.sender_name}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", mb: 1 }}>
                            <LocationOnOutlined />
                            <Typography variant="body1" sx={{ ml: 2 }}>
                                <b>Shipping Address:</b>{" "}
                                {`${rowSelectionModel?.[currentIndex]?.sender_region}, ${rowSelectionModel?.[currentIndex]?.sender_district}, ${rowSelectionModel?.[currentIndex]?.sender_street}`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", mb: 1 }}>
                            <LocalPhoneOutlined />
                            <Typography variant="body1" sx={{ ml: 2 }}>
                                <b>Phone Number:</b> {rowSelectionModel?.[currentIndex]?.sender_phone_no}
                            </Typography>
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Typography variant='h5'>Receiver Information</Typography>
                        <Box sx={{ display: "flex", mb: 1 }}>
                            <PersonOutline />
                            <Typography variant="body1" sx={{ ml: 2 }}>
                                <b>Full Name:</b> {rowSelectionModel?.[currentIndex]?.receiver_name}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", mb: 1 }}>
                            <LocationOnOutlined />
                            <Typography variant="body1" sx={{ ml: 2 }}>
                                <b>Shipping Address:</b>{" "}
                                {`${rowSelectionModel?.[currentIndex]?.receiver_region}, ${rowSelectionModel?.[currentIndex]?.receiver_district}`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", mb: 1 }}>
                            <LocalPhoneOutlined />
                            <Typography variant="body1" sx={{ ml: 2 }}>
                                <b>Phone Number:</b> {rowSelectionModel?.[currentIndex]?.receiver_phone_no}
                            </Typography>
                        </Box>
                    </Grid2>
                </Grid2>
                <Box sx={{ display: "flex", mb: 1 }}>
                    <CalendarMonthOutlined />
                    <Typography variant="body1" sx={{ ml: 2 }}>
                        Date: {formatDate(rowSelectionModel?.[currentIndex]?.created_at)}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1
                    }}
                >
                    <LocalShippingOutlined />
                    <Typography variant="body1" sx={{ mx: 2 }}>
                        Order Status:
                    </Typography>
                    <Chip
                        color={
                            rowSelectionModel?.[currentIndex]?.order_status ===
                                "DELIVERED"
                                ? "success"
                                : "warning"
                        }
                        label={rowSelectionModel?.[currentIndex]?.order_status}
                        sx={{
                            width: 110,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1
                    }}
                >
                    <MoneyOutlined />
                    <Typography variant="body1" sx={{ mx: 2 }}>
                        Payment Status:
                    </Typography>
                    <Chip
                        color={
                            rowSelectionModel?.[currentIndex]?.payment_status ===
                                "COMPLETED"
                                ? "success"
                                : "warning"
                        }
                        label={rowSelectionModel?.[currentIndex]?.payment_status}
                        sx={{
                            width: 110,
                        }}
                    />
                </Box>
                <Grid2 container spacing={2} sx={{ mt: 4 }}>
                    <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography variant='h5'>Payment Information</Typography>
                        <Typography>
                            <b>Total Product Cost:</b> {formatMoney(rowSelectionModel?.[currentIndex]?.product_name ? rowSelectionModel?.[currentIndex]?.discount_amount : rowSelectionModel?.[currentIndex]?.product_amount)}
                        </Typography>
                        <Typography>
                            <b>Shipping Cost:</b> {formatMoney(rowSelectionModel?.[currentIndex]?.shipping_cost)}
                        </Typography>
                        <Typography>
                            <b>Return Cost:</b> {formatMoney(rowSelectionModel?.[currentIndex]?.return_amount)}
                        </Typography>
                        <Typography>
                            <b>Total Cost:</b>{" "}
                            {formatMoney((rowSelectionModel?.[currentIndex]?.product_name ? rowSelectionModel?.[currentIndex]?.discount_amount : rowSelectionModel?.[currentIndex]?.product_amount) + rowSelectionModel?.[currentIndex]?.shipping_cost)}
                        </Typography>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Typography variant='h5'>Status Tracking</Typography>
                        <List sx={{ width: '100%' }}>
                            {rowSelectionModel?.[currentIndex]?.order_status_tracking && rowSelectionModel?.[currentIndex]?.order_status_tracking?.map((item) => {

                                return (
                                    <React.Fragment key={item.id}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar sx={{ bgcolor: item.completed ? "primary.main" : "gray", width: 20, height: 20 }}>
                                                    <Circle color='background.paper' />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={item.full_name}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={{ color: 'text.primary', display: 'inline' }}
                                                        >
                                                            {item.order_status}
                                                        </Typography>
                                                        {item.short_description !== "" && " - " + item.short_description} {item.created_at && "at"} {item.created_at && formatDate(item.created_at)}
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between", mx: 2, mb: 2 }}>
                <Button
                    disabled={currentIndex === 0}
                    variant='contained'
                    size='small'
                    onClick={handlePrevious}
                >
                    Previous
                </Button>
                <Button
                    disabled={rowSelectionModel.length - 1 === currentIndex}
                    variant='contained'
                    size='small'
                    onClick={handleNext}
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewOrder
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';
import { authGetRequest } from '../../services/api-service';
import { getProductsOrdersTopRegionsUrl } from '../../services/urls';
import { Skeleton } from '@mui/material';

export default function TopRegionsOrdersBarChart() {
  const theme = useTheme();
  const colorPalette = [
    (theme.vars || theme).palette.primary.dark,
    (theme.vars || theme).palette.primary.main,
    (theme.vars || theme).palette.primary.light,
  ];
  const [topRegions, setTopRegions] = React.useState([]);
  const [isLoadingTopRegions, setIsLoadingTopRegions] = React.useState(true);

  const getProductsOrdersTopRegions = React.useCallback(
    () => {
      authGetRequest(
        getProductsOrdersTopRegionsUrl,
        (data) => {
          setTopRegions(data);
          setIsLoadingTopRegions(false);
        },
        (error) => {
          setIsLoadingTopRegions(false);
        },
      )
    }, [])

  React.useEffect(() => {
    getProductsOrdersTopRegions()
  }, [getProductsOrdersTopRegions]);

  return (
    <>
      {isLoadingTopRegions && <Skeleton variant='rectangular' height={250} />}
      {!isLoadingTopRegions &&
        <Card variant="outlined" sx={{ width: '100%' }}>
          <CardContent>
            <Typography component="h2" variant="subtitle2" gutterBottom>
              Top region orders
            </Typography>
            <BarChart
              borderRadius={8}
              colors={colorPalette}
              xAxis={[
                {
                  scaleType: 'band',
                  categoryGapRatio: 0.5,
                  data: topRegions.map(label => label.region),
                },
              ]}
              series={[
                {
                  id: 'orders',
                  label: 'Orders',
                  data: topRegions.map(value => value.total_orders),
                  stack: 'A',
                },
              ]}
              height={250}
              margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
              grid={{ horizontal: true }}
              slotProps={{
                legend: {
                  hidden: true,
                },
              }}
            />
          </CardContent>
        </Card>
      }
    </>
  );
}

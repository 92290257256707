export const userFormFields = [
    { name: "first_name", type: "text", label: "First Name", notRequired: false },
    { name: "last_name", type: "text", label: "Last Name", notRequired: false },
    { name: "email", type: "email", label: "Email", notRequired: false },
    { name: "phone_no", type: "text", label: "Phone Number (2557xxxxxxxx)", notRequired: false },
    {
        name: "gender",
        type: "select",
        label: "Gender",
        items: [
            { value: 'MALE', label: "MALE", },
            { value: 'FEMALE', label: "FEMALE", },
        ],
        notRequired: false
    },
]

export const parcelTypeFormFields = [
    { name: "parcel_type", type: "text", label: "Parcel Type", notRequired: false },
]

export const regionFormFields = [
    { name: "region_name", type: "text", label: "Region", notRequired: false },
]

export const districtFormFields = [
    { name: "district_name", type: "text", label: "District", notRequired: false },
]

export const wardFormFields = [
    { name: "ward", type: "text", label: "Ward", notRequired: false },
]
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { routes } from './routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthConsumer, AuthProvider } from './contexts/auth-context';
import Loader from './components/Loader';
import { ToastContainer } from 'react-toastify';

const SplashScreen = () => <Loader />;

function App() {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AuthProvider>
            <AuthConsumer>
              {(auth) => auth.isLoading ?
                <SplashScreen /> :
                <RouterProvider router={routes} />
              }
            </AuthConsumer>
          </AuthProvider>
        </LocalizationProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;

import { createBrowserRouter } from "react-router-dom";
import SignIn from '../sign-in/SignIn';
import Home from "../pages/home/Home";
import Orders from "../pages/orders/Orders";
import Users from "../pages/users/Users";
import Settings from "../pages/settings/Settings";

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "orders",
        element: <Orders />,
    },
    {
        path: "users",
        element: <Users />,
    },
    {
        path: "settings",
        element: <Settings />,
    },
    {
        path: "login",
        element: <SignIn />,
    },
]);